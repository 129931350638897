import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import kynnet1 from '../images/kynnet1.jpeg';
import kynnet2 from '../images/kynnet2.jpeg';
import kynnet3 from '../images/kynnet3.jpeg';
import kynnet4 from '../images/kynnet4.jpeg';
import kynnet5 from '../images/kynnet5.jpeg';
import kynnet6 from '../images/kynnet6.jpeg';
import kosmetologi from '../images/avatar/svetlana.jpeg';

const Footcare = () => {
    return (
        <Layout>
            <section className="hero" data-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Kynnet</h1>
                        </div>
                    </div>
                </div>
                <div className="herobg heroimage7"></div>
            </section>
            <section className="py-3">
                <div className="container">
                    <div className="row justify-content-center sticky-container">
                        <div className="col-md-10">
                            <h2 className="mb-35 text-center">
                                Manikyyri ja kynsien hoito palvelut
                            </h2>
                            <h4 className="mb-35 ls2">Palvelut ja hinnasto</h4>
                            <div className="post-author author-content">
                                <h5 className=" mb-15 ls2">
                                    Pikamanikyyri &#40;noin 45 min&#41; alk. 30€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Perusamanikyyri &#40;noin 60 min&#41; alk.
                                    40€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Geelilakkaus uudet &#40;noin 1,5-2h&#41;
                                    alk. 50€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Geelilakkaus huolto &#40;noin 1,5-2h&#41;
                                    alk. 50€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Rakennekynnet uudet &#40;noin 2-2,5h&#41;
                                    alk. 65€
                                </h5>
                            </div>
                            <div className="post-author author-content">
                                <h5 className="mb-15 ls2">
                                    Rakennekynnet huolto &#40;noin 2-2,5h&#41;
                                    alk. 65€
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="proj-container">
                    <div className="container my-3">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box-im">
                                    <div
                                        className="image-zoom"
                                        data-dsn-grid="parallax"
                                        data-dsn-grid-move="20"
                                    >
                                        <Link
                                            className="single-image"
                                            to="/kynnet"
                                        ></Link>
                                        <img
                                            src={kosmetologi}
                                            alt="Salon Looking Good - Kynnet Svetlana"
                                        />
                                    </div>
                                    <div className="caption">
                                        <span>Salon Looking Good, 2024</span> -
                                        Kynnet Svetlana
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
                                <h4>
                                    Varaa kynsien hoito aikasi jo tänään{' '}
                                    <a
                                        href="https://timma.fi/yritys/smart-art-beauty-itis"
                                        referrerPolicy="no-referrer"
                                        target="__blank"
                                    >
                                        Timmasta
                                    </a>
                                    .
                                </h4>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-12 my-3">
                                        <h3 className="text-center">Töitä</h3>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet1}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet2}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet3}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet4}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet5}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-im">
                                            <div
                                                className="image-zoom"
                                                data-dsn-grid="parallax"
                                                data-dsn-grid-move="20"
                                            >
                                                <Link
                                                    className="single-image"
                                                    to="/jalkahoito"
                                                ></Link>
                                                <img
                                                    src={kynnet6}
                                                    alt="Salon Looking Good - Kynnet"
                                                />
                                            </div>
                                            <div className="caption">
                                                <span>
                                                    Salon Looking Good, 2024
                                                </span>{' '}
                                                - Kynnet
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Footcare;
